import React from "react";
import { Map, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import data from "../../data/incidents.json";
import style from "../style/leaflet.css";

function YMap() {
  const viewPort = {
    lat: 15.5,
    lng: 46,
    zoom: 7
  };

  const position = [viewPort.lat, viewPort.lng];

  return (
    <div css={{ height: "100%" }}>
      {typeof window !== "undefined" ? (
        <Map
          center={position}
          zoom={viewPort.zoom}
          scrollWheelZoom={false}
          className="leaflet-map"
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
          />
          {data.map(unit => {
            return (
              <Marker
                key={unit.code}
                position={{
                  lat: unit.latitude,
                  lng: unit.longitude
                }}
                riseOnHover={true}
                opacity="0"
              >
                <Popup>
                  <span>
                    {unit.title} - {unit.code}
                  </span>
                </Popup>
                <Circle
                  center={{
                    lat: unit.latitude,
                    lng: unit.longitude
                  }}
                  fill={true}
                  stroke={false}
                  opacity={0}
                  radius={5000}
                  fillOpacity={0.7}
                />
              </Marker>
            );
          })}
        </Map>
      ) : null}
    </div>
  );
}

export default YMap;
